import React from "react";
import "./App.css";
// import { Container, Button, Icon } from "@material-ui/core";
import Snake from "snake";
import Drawer from "./components/Drawer";
// import GalleryList from "./components/GalleryList";
// import SubmitForm from "./components/SubmitForm";
// import ColorMenu from "./components/ColorMenu";
// import * as api from "./api";
import { isValidSequence } from "./utils";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shapes: [],
      drawerOpened: false,
      colorOpened: false,
    };

    this.canvasContainerRef = React.createRef();
    this.submitFormRef = React.createRef();
    this.rc = null;
  }

  async componentDidMount() {
    this.rc = new Snake(this.canvasContainerRef.current);
    this.rc.ul.running = true;
    await this.rc.initSnake(
      `${process.env.PUBLIC_URL}/block.gltf`,
      `${process.env.PUBLIC_URL}/matcap.jpg`
    );

    // not awaited
    // this.loadShapes();
    const searchParams = new URLSearchParams(window.location.search);
    const sequenceStr = searchParams.get("s");
    const color1 = searchParams.get("c1");
    const color2 = searchParams.get("c2");
    if (isValidSequence(sequenceStr)) {
      // console.log(color1, color2)
      if (color1 && color2) {
        this.rc.snake.mat1.color.set(color1);
        this.rc.snake.mat2.color.set(color2);
      }
      await this.rc.snake.setSequenceSequential(sequenceStr);
      await this.rc.snakeMgr.focusCamera();
    }
  }

  handleResetClick = async () => {
    await this.rc.snake.reset();
    await this.rc.snakeMgr.focusCamera();
  };

  // handleItemClick = async (item) => {
  //   this.setState({ drawerOpened: false });

  //   // not awaited
  //   api.incrementViewCount(item.id);

  //   window.history.pushState(item.sequence, "snake", `?s=${item.sequence}`);

  //   await this.rc.snake.reset();
  //   await this.rc.snakeMgr.focusCamera();
  //   await this.rc.snake.setSequenceSequential(item.sequence);
  //   await this.rc.snakeMgr.focusCamera();
  // };

  handleDrawerToggle = () => {
    this.setState((state) => {
      return {
        drawerOpened: !state.drawerOpened,
        colorOpened: false,
      };
    });
  };

  handleColorToggle = () => {
    this.setState((state) => {
      return { colorOpened: !state.colorOpened };
    });
  };

  handleCameraClick = async () => {
    this.rc.snakeMgr.selected = undefined;
    await this.rc.snakeMgr.focusCamera();
    // console.log(this.rc.snakeMgr.controls.camera.zoom)
    // console.log(this.rc.snakeMgr.canvas.clientWidth)

    // small screen
    if (this.rc.snakeMgr.canvas.clientWidth < 500) {
      this.rc.snakeMgr.controls.camera.zoom = 2.0;
      this.rc.snakeMgr.controls.camera.updateProjectionMatrix();
    }
   
    const sequence = this.rc.snake.getSequence();
    const image = this.rc.snakeMgr.canvas.clientWidth < 500 ? this.rc.getScreenshot(100, 100) : this.rc.getScreenshot(150, 150);
    // console.log(image);

    // back to normal zoom
    if (this.rc.snakeMgr.controls.camera.zoom > 1.0) {
      this.rc.snakeMgr.controls.camera.zoom = 1.0;
      this.rc.snakeMgr.controls.camera.updateProjectionMatrix();
    }

    const color1 = "#" + this.rc.snake.mat1.color.getHexString();
    const color2 = "#" + this.rc.snake.mat2.color.getHexString();
    // console.log(color1, color2);

    let errorMsg = "";
    if (this.rc.snake.hasCollision) {
      errorMsg = "Your shape has errors!";
    } else if (/^0+$/.test(sequence)) {
      errorMsg = "That's a blank shape!";
    }

    // window.history.pushState(sequence, "snake", `?s=${sequence}`);

    // this.submitFormRef.current.open(sequence, image, errorMsg);

    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(
        ["action:saveShape", sequence, color1, color2, image, errorMsg].join("|")
      );
    if (document.ReactNativeWebView)
      document.ReactNativeWebView.postMessage(
        ["action:saveShape", sequence, color1, color2, image, errorMsg].join("|")
      );
  };

  handleOpenGallery = async () => {
    // console.log(window, document);
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(
        ["action:openGallery"].join("|")
      );
    if (document.ReactNativeWebView)
      document.ReactNativeWebView.postMessage(
        ["action:openGallery"].join("|")
      );
  };

  render() {
    return (
      <div className="App">
        <div className="canvas bg_checker" ref={this.canvasContainerRef} />
        {/* <ColorMenu
          opened={this.state.colorOpened}
          onChange1={(e) => this.rc.snake.mat1.color.set(e.target.value)}
          onChange2={(e) => this.rc.snake.mat2.color.set(e.target.value)}
          onToggle={this.handleColorToggle}
        /> */}
        {
          window.location.pathname === "/" && <Drawer
            opened={this.state.colorOpened}
            onToggle={this.handleColorToggle}
            onCamera={this.handleCameraClick}
            onOpenGallery={this.handleOpenGallery}
            onRotateLeft={() => this.rc.snakeMgr.rotateSelected(1)}
            onRotateRight={() => this.rc.snakeMgr.rotateSelected(-1)}
            onMoveLeft={() => this.rc.snakeMgr.decrementSelected()}
            onMoveRight={() => this.rc.snakeMgr.incrementSelected()}
            onReset={this.handleResetClick}
            onFocus={() => this.rc.snakeMgr.focusCamera()}
            onChange1={(e) => this.rc.snake.mat1.color.set(e.target.value)}
            onChange2={(e) => this.rc.snake.mat2.color.set(e.target.value)}
          >
          </Drawer>
        }

        {/* <SubmitForm
          onSubmit={(data) => api.postShape(data)}
          ref={this.submitFormRef}
        /> */}
      </div>
    );
  }
}

export default App;
