import React from "react";
import { Fab, Icon } from "@material-ui/core";

const Drawer = (props) => {
  const style = {};
  let panelClassName = "colorpanel";
  if (props.opened) {
    panelClassName += " open";
  }
  return (
    <>
      <div className="drawer" style={style}>
        <div className="bar">
          <div className="left">
            <Fab onClick={props.onReset}>
              <Icon>refresh</Icon>
            </Fab>
            <Fab onClick={props.onFocus}>
              <Icon>center_focus_strong</Icon>
            </Fab>
          </div>
          <div className="center">
            <Fab onClick={props.onOpenGallery}>
              <Icon>{"collections"}</Icon>
            </Fab>
            <Fab onClick={props.onMoveLeft}>
              <Icon>arrow_backward</Icon>
            </Fab>
            <Fab onClick={props.onRotateLeft}>
              <Icon>undo</Icon>
            </Fab>
            <Fab onClick={props.onRotateRight}>
              <Icon>redo</Icon>
            </Fab>
            <Fab onClick={props.onMoveRight}>
              <Icon>arrow_forward</Icon>
            </Fab>
          </div>
          <div className="right">
            {/* <Fab onClick={props.onOpenGallery}>
              <Icon>{"collections"}</Icon>
            </Fab> */}
            <div style={{ position: "absolute", right: 80 }}>
              <Fab onClick={props.onCamera}>
                <Icon>camera_alt</Icon>
              </Fab>
            </div>

            <div className="colormenu">
              <Fab className={panelClassName} variant="extended">
                <input type="color" defaultValue="#ffffff" onChange={props.onChange1} />
                <input type="color" defaultValue="#8888ff" onChange={props.onChange2} />
              </Fab>
              <Fab onClick={props.onToggle}>
                <Icon>palette</Icon>
              </Fab>
            </div>

          </div>
        </div>
        <div className="content">{props.children}</div>
      </div>
    </>
  );
};

export default Drawer;
